<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5">
          Planos de assinatura
          <v-btn
            fab
            color="primary accent-2"
            x-small
            @click="addItem"
            v-if="!context.provider.isReadonly"
            v-auth="'SUBSCRIPTION_PLAN.ADD'"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-card-text>
            <Paginator
              v-bind.sync="planList.pagingOptions"
              @page="pageSelected"
            ></Paginator>

            <v-data-table
              :headers="planList.headers"
              :items="planList.data"
              :items-per-page="25"
              :disable-sort="true"
              :loading="planList.loading"
              no-data-text=""
              loading-text="Carregando dados..."
              class="app-table hover-table"
              @click:row="viewItem"
              hide-default-footer
            >
              <template v-slot:[`item.name`]="{ item }">
                <span class="list-item-title">
                  {{ item.full_name || item.name }}
                </span>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  small
                  :color="
                    item.status
                      | enum(data.subscriptionPlanStatus, 'id', 'class')
                  "
                  dark
                  >{{ item.status | enum(data.subscriptionPlanStatus) }}</v-chip
                >
              </template></v-data-table
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { eventHub } from "@/main";
import subscriptionPlanApi from "@/common/api/subscription-plan.api";
import clientApi from "@/common/api/client.api";
import subscriptionPlanStatus from "@/common/data/subscription-plan-status.data";
import Paginator from "@/components/layout/Paginator";

export default {
  name: "SubscriptionPlanList",

  components: { Paginator },

  data: () => ({
    data: {
      subscriptionPlanStatus: subscriptionPlanStatus,
    },
    context: { provider: {} },
    planList: {
      headers: [
        { text: "Nome", value: "name" },
        { text: "Assinantes", value: "subscribers", align: "center" },
        { text: "Máx. dispositivos", value: "max_slots", align: "center" },
        { text: "Status", value: "status" },
      ],
      data: [],
      loading: false,
      pagingOptions: {
        currentPage: 1,
      },
    },
  }),

  beforeDestroy() {
    eventHub.$off("SELECTED_CLIENT");
  },

  mounted() {
    eventHub.$on("SELECTED_CLIENT", () => this.list(true));
  },

  created() {
    this.list();
  },

  methods: {
    pageSelected(page) {
      this.planList.pagingOptions.currentPage = page;
      this.list();
    },

    addItem() {
      this.$router.push(`planos-assinatura/novo`);
    },

    list(resetPage) {
      if (resetPage) {
        this.planList.pagingOptions.currentPage = 1;
      }

      let filter = {
        providerId: clientApi.getSelectedClient().id,
        page: this.planList.pagingOptions.currentPage,
      };

      this.planList.loading = true;
      subscriptionPlanApi.list(filter).then((result) => {
        this.planList.loading = false;
        this.planList.data = result.data.data;
        this.context = result.data._context;
        this.planList.pagingOptions = result.data.pagingOptions;
      });
    },

    viewItem(item) {
      this.$router.push(`planos-assinatura/${item.id}`);
    },
  },
};
</script>

<style lang="scss">
</style>
